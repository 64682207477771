var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.mapPosition)?_c('GmapMap',{staticClass:"ratio-5by2",attrs:{"center":_vm.mapPosition,"zoom":10,"options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
    },"map-type-id":"roadmap"}},[_c('GmapMarker',{attrs:{"position":_vm.mapPosition}})],1):_vm._e(),_c('div',{staticClass:"c w-xxl mb-xl"},[_c('SectionTitle',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"color-secondary"},[(_vm.settings.company_email)?_c('div',{staticClass:"mb-xl color-accent text-uppercase weight-b"},[_vm._v(" "+_vm._s(_vm.$t('contact.email'))),_c('br'),_c('a',{staticClass:"color-secondary weight-r",attrs:{"href":'mailto:' + _vm.settings.company_email}},[_vm._v(" "+_vm._s(_vm.settings.company_email)+" ")])]):_vm._e(),(_vm.settings.company_phone)?_c('div',{staticClass:"color-accent text-uppercase weight-b"},[_vm._v(" "+_vm._s(_vm.$t('contact.phone'))),_c('br'),_c('a',{staticClass:"color-secondary weight-r",attrs:{"href":'tel:' + _vm.settings.company_phone}},[_vm._v(" "+_vm._s(_vm.settings.company_phone)+" ")])]):_vm._e(),_c('OpeningHours',{attrs:{"days":_vm.settings.opening_hours || [],"translations":{
                        opened: _vm.$t('openinghours.opened'),
                        closed: _vm.$t('openinghours.closed'),
                        to: _vm.$t('openinghours.to'),
                        from: _vm.$t('openinghours.from'),
                        monday: _vm.$t('openinghours.monday'),
                        tuesday: _vm.$t('openinghours.tuesday'),
                        wednesday: _vm.$t('openinghours.wednesday'),
                        thursday: _vm.$t('openinghours.thursday'),
                        friday: _vm.$t('openinghours.friday'),
                        saturday: _vm.$t('openinghours.saturday'),
                        sunday: _vm.$t('openinghours.sunday'),
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [(data.nextOpen && data.nextOpen.msg)?_c('span',{staticClass:"bg-info-theme color-contrast color-primary rounded ph-m pv-s text-center display-inlineblock mt-l"},[_vm._v(" "+_vm._s(data.nextOpen.msg)+" ")]):_vm._e()]}}])})],1)]},proxy:true},{key:"right",fn:function(){return [_c('address',[(_vm.settings.company_address && _vm.settings.company_address.place_id)?_c('a',{attrs:{"href":'https://www.google.com/maps/search/?api=1&query=Kerna%20Luxury%20Stones&query_place_id=' + _vm.settings.company_address.place_id,"target":"_blank","rel":"nofollow noreferrer"}},[_vm._v(" "+_vm._s(_vm.settings.company_address.parts.street)+" "+_vm._s(_vm.settings.company_address.parts.number)),_c('br'),_vm._v(" "+_vm._s(_vm.settings.company_address.parts.zip)+" "+_vm._s(_vm.settings.company_address.parts.city)+" ")]):_vm._e(),_c('br')])]},proxy:true}])})],1),_c('HorizontalContentSlider',{attrs:{"items":_vm.showcaseImages},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('img',{staticClass:"rounded cursor-pointer",attrs:{"src":item,"loading":"lazy"},on:{"click":function($event){return _vm.onHorizontalImagesClick(index)}}})]}}])}),_c('div',{staticClass:"c w-l section-margin-top"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('menu.contact')))]),_c('br'),_c('h4',{staticClass:"color-secondary mt-0"},[_vm._v(_vm._s(_vm.$t('contact.subtitle')))]),_c('div',{staticClass:"flex columns -wrap -space-between"},[_c('div',{staticClass:"col-12 col-6__m-up col-5__l-up"},[_c('Form',{staticClass:"mt-xl",attrs:{"formname":"Contactformulier","buttonClass":"bg-white","data":_vm.formdata,"to":[{ name: _vm.settings.company_name, email: _vm.settings.company_email }],"translations":{
                        submit: _vm.$t('contact.submit'),
                        submit_success: _vm.$t('contact.submit_success'),
                        submit_error: _vm.$t('contact.submit_error'),
                    }}},[_c('fieldset',[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('contact.name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.name),expression:"formdata.name"}],attrs:{"type":"text","id":"name","autocomplete":"name","required":""},domProps:{"value":(_vm.formdata.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formdata, "name", $event.target.value)}}})]),_c('fieldset',[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('contact.email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.email),expression:"formdata.email"}],attrs:{"type":"email","id":"email","autocomplete":"email","required":""},domProps:{"value":(_vm.formdata.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formdata, "email", $event.target.value)}}})]),_c('fieldset',[_c('label',{attrs:{"for":"message"}},[_vm._v(_vm._s(_vm.$t('contact.message')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.text),expression:"formdata.text"}],attrs:{"id":"message","required":""},domProps:{"value":(_vm.formdata.text)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formdata, "text", $event.target.value)}}})])])],1),_c('div',{staticClass:"col-12 col-5__m-up col-5__l-up color-secondary mt-xxl__m-down",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.settings.address_text))}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }