<template>
    <div>
        <GmapMap v-if="mapPosition" class="ratio-5by2" :center="mapPosition" :zoom="10" :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
        }" map-type-id="roadmap">
            <GmapMarker :position="mapPosition" />
        </GmapMap>

        <div class="c w-xxl mb-xl">
            <SectionTitle>
                <template v-slot:left>
                    <div class="color-secondary">
                        <div class="mb-xl color-accent text-uppercase weight-b" v-if="settings.company_email">
                            {{ $t('contact.email') }}<br />
                            <a :href="'mailto:' + settings.company_email" class="color-secondary weight-r">
                                {{ settings.company_email }}
                            </a>
                        </div>

                        <div class="color-accent text-uppercase weight-b" v-if="settings.company_phone">
                            {{ $t('contact.phone') }}<br />
                            <a :href="'tel:' + settings.company_phone" class="color-secondary weight-r">
                                {{ settings.company_phone }}
                            </a>
                        </div>

                        <OpeningHours :days="settings.opening_hours || []" :translations="{
                            opened: $t('openinghours.opened'),
                            closed: $t('openinghours.closed'),
                            to: $t('openinghours.to'),
                            from: $t('openinghours.from'),
                            monday: $t('openinghours.monday'),
                            tuesday: $t('openinghours.tuesday'),
                            wednesday: $t('openinghours.wednesday'),
                            thursday: $t('openinghours.thursday'),
                            friday: $t('openinghours.friday'),
                            saturday: $t('openinghours.saturday'),
                            sunday: $t('openinghours.sunday'),
                        }">
                            <template v-slot:default="{ data }">
                                <span v-if="data.nextOpen && data.nextOpen.msg"
                                    class="bg-info-theme color-contrast color-primary rounded ph-m pv-s text-center display-inlineblock mt-l">
                                    {{ data.nextOpen.msg }}
                                </span>
                            </template>
                        </OpeningHours>
                    </div>
                </template>

                <template v-slot:right>
                    <address>
                        <a v-if="settings.company_address && settings.company_address.place_id"
                            :href="'https://www.google.com/maps/search/?api=1&query=Kerna%20Luxury%20Stones&query_place_id=' + settings.company_address.place_id"
                            target="_blank" rel="nofollow noreferrer">
                            {{ settings.company_address.parts.street }} {{ settings.company_address.parts.number }}<br />
                            {{ settings.company_address.parts.zip }} {{ settings.company_address.parts.city }}
                        </a><br />
                    </address>
                </template>
            </SectionTitle>
        </div>

        <HorizontalContentSlider :items="showcaseImages">
            <template v-slot:item="{ item, index }">
                <img @click="onHorizontalImagesClick(index)" class="rounded cursor-pointer" :src="item" loading="lazy" />
            </template>
        </HorizontalContentSlider>

        <div class="c w-l section-margin-top">
            <h2 class="mb-0">{{ $t('menu.contact') }}</h2><br />
            <h4 class="color-secondary mt-0">{{ $t('contact.subtitle') }}</h4>

            <div class="flex columns -wrap -space-between">
                <div class="col-12 col-6__m-up col-5__l-up">
                    <Form class="mt-xl" formname="Contactformulier" buttonClass="bg-white" :data="formdata"
                        :to="[{ name: settings.company_name, email: settings.company_email }]" :translations="{
                            submit: $t('contact.submit'),
                            submit_success: $t('contact.submit_success'),
                            submit_error: $t('contact.submit_error'),
                        }">
                        <fieldset>
                            <label for="name">{{ $t('contact.name') }}</label>
                            <input type="text" id="name" v-model="formdata.name" autocomplete="name" required />
                        </fieldset>

                        <fieldset>
                            <label for="email">{{ $t('contact.email') }}</label>
                            <input type="email" id="email" v-model="formdata.email" autocomplete="email" required />
                        </fieldset>

                        <fieldset>
                            <label for="message">{{ $t('contact.message') }}</label>
                            <textarea id="message" v-model="formdata.text" required />
                        </fieldset>
                    </Form>
                </div>

                <div class="col-12 col-5__m-up col-5__l-up color-secondary mt-xxl__m-down"
                    v-html="nl2br(settings.address_text)" />

            </div>
        </div>
    </div>
</template>

<script>
import { nl2br } from '@projectfive/services';

export default {
    name: 'Contact',
    components: {
        Form: () => import('@/components/ui/Form.vue'),
        HorizontalContentSlider: () => import('@/components/ui/HorizontalContentSlider.vue'),
        SectionTitle: () => import('@/components/SectionTitle.vue'),
        OpeningHours: () => import('@/components/ui/Openinghours/index.vue'),
    },
    data() {
        return {
            formdata: {
                name: '',
                email: '',
                text: '',
            },
        };
    },
    methods: {
        onHorizontalImagesClick(index) {
            const imgs = [...this.showcaseImages];
            const reorderArray = (array, split) => {
                const lastPart = array.splice(0, split);
                const newArray = [...array, ...lastPart];
                return newArray;
            };
            const reorderedImages = index > 0 ? reorderArray(imgs, index) : imgs;
            if (reorderedImages.length) {
                this.$store.commit('updateOverlay', reorderedImages);
            }
        },

        nl2br,
    },
    computed: {
        mapPosition() {
            if (this.settings.company_address?.coords?.lat) {
                return {
                    lat: this.settings.company_address?.coords?.lat,
                    lng: this.settings.company_address?.coords?.lng,
                };
            }
            return false;
        },

        showcaseImages() {
            const images = [];
            for (let i = 0; i < 12; i += 1) {
                if (this.texts[`contact_image${i}`]) {
                    images.push(this.$options.filters.cdnUrl(this.texts[`contact_image${i}`]));
                }
            }
            return images;
        },

        texts() {
            return this.$store.state.data;
        },

        settings() {
            return this.texts.settings?.[0] || {};
        },
    },
};
</script>
